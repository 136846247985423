import { Inject, Injectable, OnDestroy } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { Router } from '@angular/router';
import { distinct, map } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { Subscription } from 'rxjs';
import { WINDOW } from '@providers/window.provider';
import { Env, ENV } from '../providers/env.provider';
import { UserTokenHeavy } from '../types/User';
import { CentralServerService } from './central-server.service';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService implements OnDestroy {
  private subscription: Subscription;

  public constructor(
    private router: Router,
    private centralService: CentralServerService,
    @Inject(ENV) private env: Env,
    @Inject(WINDOW) private window: Window,
  ) {}

  public init(): void {
    if (!this.env().apiUrlEntity.mixpanelApiKey) {
      return;
    }

    mixpanel.init(this.env().apiUrlEntity.mixpanelApiKey, {
      track_pageview: true,
      persistence: 'localStorage',
    });
    this.initLocalStorageListener();
    this.initLocationListener();
    this.updateUserInContext(this.centralService.getCurrentUserSubject().getValue());
  }

  private initLocalStorageListener(): void {
    this.centralService
      .getCurrentUserSubject()
      .pipe(distinct())
      .subscribe((user) => {
        this.updateUserInContext(user);
      });
  }

  private initLocationListener(): void {
    this.subscription = this.router.events
      .pipe(
        map(() => this.window?.location.host ?? ''),
        distinct(),
      )
      .subscribe((host) => {
        const split = host.split('.');
        if (split.length === 1) {
          mixpanel.register_once({ tenantDomain: 'default' });
          Sentry.setContext('tenant', { tenantSubdomain: 'default' });
          Sentry.setTag('tenant', 'default');
        } else {
          Sentry.setContext('tenant', { tenantSubdomain: split[0] });
          Sentry.setTag('tenant', split[0]);
        }
      });
  }

  private updateUserInContext(user: UserTokenHeavy | undefined): void {
    if (!user) {
      mixpanel.reset();
      return;
    }

    mixpanel.identify(user.id);
    mixpanel.people.set({
      id: user.id,
      language: user.language,
      role: user.role,
      tenantId: user.tenantID,
      tenantName: user.tenantName,
      tenantSubdomain: user.tenantSubdomain,
      currency: user.currency,
    });
  }

  public trackClickEvent(buttonId: string, properties: any = {}): void {
    this.track('click', { buttonId, ...properties });
  }

  public track(id: string, action: any = {}): void {
    if (!this.env().apiUrlEntity.mixpanelApiKey) {
      return;
    }
    mixpanel.track(id, action);
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
