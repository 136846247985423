import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { buildUrl } from '@utils/url/url.util';
import { RESTServerRoute } from '../../types/Server';
import { TransactionDataResult } from '../../types/DataResult';
import { Transaction } from '../../types/Transaction';

@Injectable()
export class TransactionApiService {
  public constructor(private readonly httpClient: HttpClient) {}

  public getActiveTransactions(params: {
    Issuer?: boolean;
    ChargingStationId?: string;
    WithCompany?: boolean;
    WithSite?: boolean;
    WithSiteArea?: boolean;
    WithTag?: boolean;
    WithUser?: boolean;
    WithCar?: boolean;
    WithChargingStation?: boolean;
    Statistics?: 'ongoing';
    Limit: number;
  }): Observable<TransactionDataResult> {
    return this.httpClient.get<TransactionDataResult>(RESTServerRoute.REST_TRANSACTIONS_ACTIVE, {
      params,
    });
  }

  public getTransaction(
    chargingStationId: string,
    params: { Limit: number; ConnectorID: number; SortFields?: string; WithCar?: boolean },
  ): Observable<TransactionDataResult> {
    return this.httpClient.get<TransactionDataResult>(
      buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_TRANSACTIONS, { id: chargingStationId }),
      {
        params,
      },
    );
  }

  public getConsumptions(
    transactionId: number,
    params: { LoadAllConsumptions?: boolean } = {},
  ): Observable<Transaction> {
    return this.httpClient.get<Transaction>(
      RESTServerRoute.REST_TRANSACTION_CONSUMPTIONS.replace(':id', transactionId.toString()),
      { params },
    );
  }

  public updateTransactionPriority(transactionId: number, priority: number): Observable<void> {
    const url = buildUrl(RESTServerRoute.REST_TRANSACTION_PRIORITY, {
      id: transactionId.toString(),
    });
    return this.httpClient.put<void>(url, { transactionId, priority });
  }
}
